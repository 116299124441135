import React from 'react';
import SharedConstants from 'common/constants/SharedConstants';
import { NavLink } from 'react-router-dom';

export const AnchorWithLink = props => {
  const {
    href = '',
    children,
    targetWindow,
    additionalChild = null,
    className = '',
    name = '',
    onClickHandler = null,
    type = 'internal', // should be external if link if of out of domain
    ...rest
  } = props;

  /**
   * On Key Press Handler
   * @param {*} evt - Event Object
   */
  const onKeyPressHandler = evt => {
    // Char Code - Space and Enter
    const { SPACE, ENTER } = SharedConstants.KEY_CHAR_CODE;
    if (onClickHandler && (evt.charCode === SPACE || evt.charCode === ENTER)) {
      // if onClickHandler is a method
      onClickHandler({ name, linkTo: href, event: evt });
    }
  };

  // Generate a link based on type
  const getLink = () => {
    let linkIs = href ? href : true;
    if (type === 'external') {
      linkIs = {
        pathname: href,
      };
    }
    return linkIs;
  };

  return (
    <React.Fragment>
      <NavLink
        to={getLink()}
        target={targetWindow}
        {...rest}
        className={className ? className : 'link'}
        onClick={(event) => {
          onClickHandler && onClickHandler({ name, linkTo: href, type , event});
        }}
        onKeyPress={onKeyPressHandler}
      >
        {children}
        {additionalChild}
      </NavLink>
    </React.Fragment>
  );
};

// Default Export
export default AnchorWithLink;

import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { DISPOSITION_KEYS } from 'common/constants/SharedConstants';
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';

const {AUTO_SUGGESTIONS_PICKUP_FILTER, AUTO_SUGGESTIONS_DELIVERY_FILTER} = SEARCH_STORE_CONSTANTS;
const { DELIVERY, PICKUP, CATERING } = DISPOSITION_KEYS;
const {
  DELIVERY_PLACEHOLDER,
  PICKUP_PLACEHOLDER,
  SELECT_KFC_TITLE,
  DELIVERY_ADDRESS_TITLE,
  SWITCH_TO_TAKEAWAY_TEXT,
  RETRY_BTN_TEXT,
  DELIVERY_UNAVAILABLE_ERROR,
  NO_KFC_NEAR_YOU_ERROR,
  BROWSE_MENU_TEXT,
  STORE_CLOSE_ERROR_DELIVERY,
  STORE_CLOSE_ERROR_PICKUP,
  SAVED_ADDRESS,
  NEAR_YOU,
  DELIVER_TO_YOU,
  ORDER_MODE,
  CATERING_SCHEDULE,
  SELECT_STORE_TEXT,
  SCHEDULE_ORDER_BTN_TEXT,
  START_ORDER,
  CONFIRM_BTN_TEXT
} = TRANSLATE_MAPPING_KEY;

export const FORM = 'FORM';
export const STORES = 'STORES';
export const SAVED = 'SAVED';

export const searchStoreConfig = {
  [`${DELIVERY}_${FORM}`]: {
    placeholder: DELIVERY_PLACEHOLDER,
    serviceUnavailableError: {
      errMsg: DELIVERY_UNAVAILABLE_ERROR,
      firstBtnText: RETRY_BTN_TEXT,
      secondBtnText: SWITCH_TO_TAKEAWAY_TEXT,
      firstBtnHandler: 'focusInputBox',
      secondBtnHandler: 'switchToPickup',
    },
    orderModeScreenErrorText: STORE_CLOSE_ERROR_DELIVERY,
    modalHeaderTitle: DELIVERY_ADDRESS_TITLE,
    kfcStoresCountText: SAVED_ADDRESS,
    scheduleOrderScreenTitle: ORDER_MODE,
    shouldPopulateFirstSuggestion: false,
    storeOpenBtnText: SELECT_STORE_TEXT,
    shouldShowOrderType: true,
    shouldShowDateTime: false,
    orderModeBtnText: START_ORDER,
    filteredTypes: AUTO_SUGGESTIONS_DELIVERY_FILTER
  },
  [`${DELIVERY}_${STORES}`]: {
    serviceUnavailableError: {
      errMsg: DELIVERY_UNAVAILABLE_ERROR,
      firstBtnText: RETRY_BTN_TEXT,
      secondBtnText: SWITCH_TO_TAKEAWAY_TEXT,
      firstBtnHandler: 'focusInputBox',
      secondBtnHandler: 'switchToPickup',
    },
    modalHeaderTitle: SELECT_KFC_TITLE,
    orderModeScreenErrorText: STORE_CLOSE_ERROR_DELIVERY,
    placeholder: DELIVERY_PLACEHOLDER,
    kfcStoresCountText: DELIVER_TO_YOU,
    scheduleOrderScreenTitle: ORDER_MODE,
    shouldPopulateFirstSuggestion: true,
    storeOpenBtnText: SELECT_STORE_TEXT,
    shouldShowOrderType: true,
    shouldShowDateTime: false,
    orderModeBtnText: START_ORDER,
    filteredTypes: AUTO_SUGGESTIONS_DELIVERY_FILTER
  },
  [`${PICKUP}_${FORM}`]: {
    serviceUnavailableError: {
      errMsg: NO_KFC_NEAR_YOU_ERROR,
      firstBtnText: BROWSE_MENU_TEXT,
      firstBtnHandler: 'gotoMenuPage',
      secondBtnHandler: null,
      firstBtnClassName: 'closed-store'
    },
    modalHeaderTitle: SELECT_KFC_TITLE,
    orderModeScreenErrorText: STORE_CLOSE_ERROR_PICKUP,
    placeholder: PICKUP_PLACEHOLDER,
    kfcStoresCountText: NEAR_YOU,
    scheduleOrderScreenTitle: ORDER_MODE,
    shouldPopulateFirstSuggestion: false,
    storeOpenBtnText: SELECT_STORE_TEXT,
    shouldShowOrderType: true,
    shouldShowDateTime: false,
    orderModeBtnText: START_ORDER,
    filteredTypes: AUTO_SUGGESTIONS_PICKUP_FILTER
  },
  [`${PICKUP}_${STORES}`]: {
    serviceUnavailableError: {
      errMsg: NO_KFC_NEAR_YOU_ERROR,
      firstBtnText: BROWSE_MENU_TEXT,
      firstBtnHandler: 'gotoMenuPage',
      secondBtnHandler: null,
      firstBtnClassName: 'closed-store'
    },
    modalHeaderTitle: SELECT_KFC_TITLE,
    orderModeScreenErrorText: STORE_CLOSE_ERROR_PICKUP,
    placeholder: PICKUP_PLACEHOLDER,
    kfcStoresCountText: NEAR_YOU,
    scheduleOrderScreenTitle: ORDER_MODE,
    shouldPopulateFirstSuggestion: false,
    storeOpenBtnText: SELECT_STORE_TEXT,
    shouldShowOrderType: true,
    shouldShowDateTime: false,
    orderModeBtnText: START_ORDER,
    filteredTypes: AUTO_SUGGESTIONS_PICKUP_FILTER
  },
  [`${DELIVERY}_${SAVED}`]: {
    serviceUnavailableError: {
      errMsg: DELIVERY_UNAVAILABLE_ERROR,
      firstBtnText: RETRY_BTN_TEXT,
      secondBtnText: SWITCH_TO_TAKEAWAY_TEXT,
      firstBtnHandler: 'openDeliveryAddressScreen',
      secondBtnHandler: 'switchToPickup',
    },
    modalHeaderTitle: SELECT_KFC_TITLE,
    orderModeScreenErrorText: STORE_CLOSE_ERROR_DELIVERY,
    placeholder: DELIVERY_PLACEHOLDER,
    kfcStoresCountText: DELIVER_TO_YOU,
    scheduleOrderScreenTitle: ORDER_MODE,
    storeOpenBtnText: SELECT_STORE_TEXT,
    shouldShowOrderType: true,
    shouldShowDateTime: false,
    orderModeBtnText: START_ORDER,
    filteredTypes: AUTO_SUGGESTIONS_DELIVERY_FILTER
  },
  [`${CATERING}_${FORM}`]: {
    serviceUnavailableError: {
      errMsg: NO_KFC_NEAR_YOU_ERROR,
      firstBtnText: BROWSE_MENU_TEXT,
      firstBtnHandler: 'gotoMenuPage',
      secondBtnHandler: null,
      firstBtnClassName: 'closed-store'
    },
    modalHeaderTitle: SELECT_KFC_TITLE,
    orderModeScreenErrorText: STORE_CLOSE_ERROR_PICKUP,
    placeholder: PICKUP_PLACEHOLDER,
    kfcStoresCountText: NEAR_YOU,
    scheduleOrderScreenTitle: CATERING_SCHEDULE,
    shouldPopulateFirstSuggestion: false,
    storeOpenBtnText: SCHEDULE_ORDER_BTN_TEXT,
    shouldShowOrderType: false,
    shouldShowDateTime: true,
    orderModeBtnText: CONFIRM_BTN_TEXT,
    filteredTypes: AUTO_SUGGESTIONS_PICKUP_FILTER
  },
  [`${CATERING}_${STORES}`]: {
    serviceUnavailableError: {
      errMsg: NO_KFC_NEAR_YOU_ERROR,
      firstBtnText: BROWSE_MENU_TEXT,
      firstBtnHandler: 'gotoMenuPage',
      secondBtnHandler: null,
      firstBtnClassName: 'closed-store'
    },
    modalHeaderTitle: SELECT_KFC_TITLE,
    orderModeScreenErrorText: STORE_CLOSE_ERROR_PICKUP,
    placeholder: PICKUP_PLACEHOLDER,
    kfcStoresCountText: NEAR_YOU,
    scheduleOrderScreenTitle: CATERING_SCHEDULE,
    shouldPopulateFirstSuggestion: false,
    storeOpenBtnText: SCHEDULE_ORDER_BTN_TEXT,
    shouldShowOrderType: false,
    shouldShowDateTime: true,
    orderModeBtnText: CONFIRM_BTN_TEXT,
    filteredTypes: AUTO_SUGGESTIONS_PICKUP_FILTER
  },
  
};

import { CART_PAGE_CONSTANTS } from '../Constants/CartPageConstants';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import { getAnalyticsParamDefaultValue, getDataLayer, isEmptyArray, getTenantCountryCode } from 'common/utilities/utils';
import { getDurationWithDiffInHours } from 'common/utilities/dayJsUtils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { TENANT_IMAGE_BASE_URL } from '@kfc-global/react-shared/config/config.constants';
import { COUNTRY_CODE } from 'common/constants/SharedConstants';

export const modifyData = (data, listData, tenantId, currencyConversionFactor, addHope, carryBag, bucketSize, country) => {
  const contentData = {
    cartList: [],
    cartSummary: {},
    tenantId,
    country
  };
  const { id } = data;
  contentData.cartList =
      tenantId && bucketSize && getCartListData(listData, tenantId, currencyConversionFactor, bucketSize);
  contentData.bucketId = id;
  contentData.cartSummary = {
    ...getcartSummary(data, listData, currencyConversionFactor, addHope, carryBag),
  };
  return contentData;
};

export const getcartSummary = (data, listData, currencyConversionFactor, addHope, carryBag) => {
  const { inclusiveTaxTotal = 0, additionalChargeLines = [], discountLines = [] } = data || [];
  const subtotal = data?.subtotal || data?.subTotal;
  let availableList =
    listData &&
    listData.filter(
      item =>
        !item.toRemove && item.newFoodLineItem?.item?.name !== addHope && item.newFoodLineItem?.item?.name !== carryBag,
    );
  const quantity = availableList.reduce((acc, food) => +acc + +food.newFoodLineItem?.quantity, 0);
  const additionalCharges =
    additionalChargeLines &&
    additionalChargeLines.map(charges => ({
      chargeType: charges?.chargeType || 'Additional-Charges',
      amount: (+charges?.amount / currencyConversionFactor || 0).toFixed(2),
    }));
  const discountAmounts =
      discountLines.length &&
      discountLines
        .map(obj => {
          let errorMessage = '';
          if (obj?.basicErrors?.length) {
            errorMessage = obj?.basicErrors[0]?.message;
          } else if ( obj?.promoConditionStatus?.length) {
            const errorMsg = obj?.promoConditionStatus.filter(obj=>!obj?.isValidCondition);
            errorMessage = errorMsg?.length ? errorMsg[0]?.displayMessage[0]?.value: '';
          }
          return {
          couponCode: obj?.couponCode || 'Discount-Code',
          amount: (+obj?.amount / currencyConversionFactor || 0).toFixed(2),
          status: obj.status.name,
          isError: String(obj.status.name).toUpperCase() !== 'APPLIED',
          errorMessages: errorMessage,
        }});
        const totalAdditionalCharges = (+data?.additionalChargeTotal / currencyConversionFactor || 0).toFixed(2);
  return {
    cartId: data?.id || '',
    catalog: data?.catalog,
    quantity,
    taxAmounts: (inclusiveTaxTotal / currencyConversionFactor || 0).toFixed(2),
    additionalCharges,
    totalAdditionalCharges,
    discountAmounts,
    subTotal: (+subtotal / currencyConversionFactor || 0).toFixed(2),
    totalAmount: (+data?.total / currencyConversionFactor || 0).toFixed(2),
    recommendedItems: data?.recommendedItems || [],
  };
};

export const getCartListData = (foodLines = [], tenantId, currencyConversionFactor, bucketSize) => {
    return foodLines &&
    foodLines.length &&
    foodLines.map(foodItem => {
      return {
        foodLineId: foodItem?.newFoodLineItem?.id,
        foodItemId: foodItem?.newFoodLineItem?.item?.id,
        name: foodItem?.newFoodLineItem?.item?.name,
        foodName: foodItem?.newFoodLineItem?.item?.dname[0]?.value,
        quantity: foodItem?.newFoodLineItem?.quantity,
        unitPrice: foodItem?.newFoodLineItem?.unitPrice / currencyConversionFactor,
        imageData: {
          src: `${config(TENANT_IMAGE_BASE_URL)}${tenantId}/images/items/${bucketSize}/${foodItem?.newFoodLineItem?.item?.imageName[0]?.value}.jpg`,
          altText: foodItem?.newFoodLineItem?.item?.dname[0]?.value,
        },
        modifiers: getModifiers(foodItem?.newFoodLineItem?.item?.modGrps),
        shortDescription: foodItem?.newFoodLineItem?.item?.shortDescription[0]?.value,
        customizable: foodItem?.newFoodLineItem?.item?.customizable,
        toRemove: foodItem?.toRemove,
        requireModification: foodItem?.requireModification,
        menuPrice: foodItem?.menuPrice,
        cartPrice: foodItem?.cartPrice,
        discountComponents: foodItem?.newFoodLineItem?.discountComponents,
        priceWithOutTax: foodItem?.newFoodLineItem?.item?.unitPrice / currencyConversionFactor,
        taxAmount: foodItem?.newFoodLineItem?.taxAmount / currencyConversionFactor
      };
    });
};

export const getModifiers = (mods = []) => {
  return mods.length > 0
    ? mods
        .map(modItem => modItem?.modifiers?.map(mod => mod?.dname[0]?.value).join(', '))
        .join(', ')
        .split(', ')
    : [];
};

export const setAnalyticsData = async (event, cartItems, categories = []) => {
  const { ANALYTICS_EVENTS } = CART_PAGE_CONSTANTS;

  if (event === ANALYTICS_EVENTS.EDIT) {
    await AnalyticsService.registerEvent(
      event,
      {
        '%event%': 'editCartItem',
      },
      getDataLayer,
    );
    return;
  }

  const items = cartItems.map(async (item, index) => {
    const { id = 'NA', name = 'NA' } = categories?.find(cat => cat.products.find(p => p.name === item.foodName)) || {};
    return {
      item_name: item?.foodName || 'NA',
      item_id: `${item?.foodItemId || 'NA'}`,
      price: item?.unitPrice || 'NA',
      item_category: name,
      item_variant: isEmptyArray(item?.modifiers) ? 'NA' : item?.modifiers?.reduce((acc, cv) => acc + `${cv}, `, ''),
      item_list_name: name,
      item_list_id: id,
      index: index,
      quantity: `${item?.quantity || 'NA'}`,
    };
  });

  await AnalyticsService.registerEvent(
    event,
    {
      data: {
        event,
        ecommerce: {
          [event]: {
            items,
          },
        },
      },
    },
    getDataLayer,
  );
};

export const isCartExpired = userLastActiveTime => {
  if (!userLastActiveTime || !(getTenantCountryCode() === COUNTRY_CODE.IN)) {
    return false;
  }
  const duration = getDurationWithDiffInHours(userLastActiveTime);
  return duration > CART_PAGE_CONSTANTS.CART_EXPIRE_TIME_HRS;
};

//RENDEV-12597 NA value fix for analytics - add to cart - fixed arguments order
export const getPayloadForRemoveFromCart = (cartItems, productMapById, qtyFlag) => {
  return cartItems?.map(item => {
    const { categoryId: id = 'NA', categoryName: name = 'NA' } = productMapById?.[item.foodItemId] ?? {};
    return {
      item_name: item?.foodName || 'NA',
      item_id: `${item?.foodItemId || 'NA'}`,
      price: getAnalyticsParamDefaultValue(qtyFlag, 'removeAllQty', 'quantityModified', item?.cartPrice, item?.cartPrice / item?.quantity),
      item_category: name,
      item_variant: isEmptyArray(item?.modifiers) || !item?.customizable ? 'NA' : item?.modifiers?.reduce((acc, cv) => acc + `${cv}, `, ''), //RENDEV-12570 added condition for combo product which is NOT customisable then the value should be 'NA'
      item_list_name: name,
      item_list_id: id,
      index: 1,
      quantity: getAnalyticsParamDefaultValue(qtyFlag, 'removeAllQty', 'quantityModified', item?.quantity, 1),
    };
  });
}

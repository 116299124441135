import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';
import { translateWithI18Next } from '@kfc-global/react-shared/i18n/i18N';
import { ACCOUNT_PATH, CURRENT_FAV_MENU, ECOMMERCE, NOT_AVAILABLE, COUNTRY_CODE } from 'common/constants/SharedConstants';
import { CART_PAGE_CONSTANTS } from 'organisms/CartPage/Constants/CartPageConstants';
import WELCOME_BACK_COSTANTS from 'organisms/WelcomeBackModal/Constants/Constants';
import { getAndCheckIfOneTrue, getDataLayer, getEmbeddedErrorMessageAnalytic, getTenantCountryCode } from '../../../common/utilities/utils';
import { ACCOUNT_FAVORDERS, HOMEPAGE_REORDER } from '../Constants/ReorderConstants';

const { ANALYTICS_EVENTS } = CART_PAGE_CONSTANTS;
/**
 * Method to set analytics items in the data layer object
 */
export const setAnalyticsData = async (analyticsData, field) => {
  const analyticsItems = [];
  analyticsItems.push({
    event: analyticsData.eventType,
    [field]: analyticsData.selectedData,
  });
  console.log(analyticsItems, 'reoder');
  await AnalyticsService.registerEvent(
    events?.add_to_cart?.name,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};

export const reOrderAnalyticHandler = (orderItems, currencyConversionFactor, categories) => {
  const currentPath = window.location.href;
  const itemInfo = [...orderItems].map((item, index) => {
    let priceAmt = (item.priceWithTax / currencyConversionFactor).toFixed(2);
    const itemVariant = [];
      item?.isCustomized &&
       item?.modGroups?.map(variant => {
        itemVariant.push(variant?.modifiersDetails[0]?.name);
      })
    const { id: catId = 'NA', name: catName = 'NA' } =
      categories?.find(cat => cat.products.find(p => p.name === item.name)) || {};
    return {
      item_name: item.name || 'NA',
      item_id: getAndCheckIfOneTrue(item.id, NOT_AVAILABLE),
      item_category: catName,
      price: parseFloat(priceAmt) || 'NA',
      item_variant: itemVariant.join(', ') || 'NA',
      item_list_name: currentPath?.endsWith('/account') ? translateWithI18Next(ACCOUNT_FAVORDERS) : translateWithI18Next(HOMEPAGE_REORDER),
      item_list_id: catId,
      index: index + 1,
      quantity: item.quantity,
    };
  });
  setAnalyticsData(
    {
      eventType: 'add_to_cart',
      selectedData: {
        add_to_cart: {
          items: itemInfo,
        },
      },
    },
    'ecommerce',
  );
};

export const genericReOrderAnalyticHandler = (orderItems, currencyConversionFactor, itemMapById) => {
  const currentPath = window.location.href;
  const itemInfo = [...orderItems].map((itemInfos, index) => {
    let priceAmt = (itemInfos?.unitPrice / currencyConversionFactor).toFixed(2);
    const itemVariant = [];

    itemInfos?.item?.modGrps?.forEach(variant => {
      itemVariant.push(variant?.modifiers[0]?.name);
    });
    const { categoryId: catId = NOT_AVAILABLE, categoryName: catName = NOT_AVAILABLE } =
      itemMapById?.[itemInfos?.item?.id] ?? {};
    const itemListName = localStorage.getItem(CURRENT_FAV_MENU);
    return {
      item_name: getAndCheckIfOneTrue(itemInfos?.item?.name, NOT_AVAILABLE),
      item_id: getAndCheckIfOneTrue(itemInfos?.item?.id, NOT_AVAILABLE),
      item_category: catName,
      price: getAndCheckIfOneTrue(parseFloat(priceAmt), NOT_AVAILABLE),
      item_variant: getAndCheckIfOneTrue(itemVariant.join(', '), NOT_AVAILABLE),
      item_list_name: currentPath?.endsWith(ACCOUNT_PATH) ? itemListName : translateWithI18Next(HOMEPAGE_REORDER),
      item_list_id: catId,
      index: index + 1,
      quantity: itemInfos?.quantity,
    };
  });
  setAnalyticsData(
    {
      eventType: ANALYTICS_EVENTS.ADD_TO_CART,
      selectedData: {
        add_to_cart: {
          items: itemInfo,
        },
      },
    },
    ECOMMERCE,
  );
};

export const quickReorderAnalyticFn = (isError, quickOrder, errorMsg) => {
  if(isError){
    getEmbeddedErrorMessageAnalytic(translateWithI18Next(quickOrder), errorMsg);
  }
};

export const reOrderAnalyticHandlerFn = (currencyConversionFactor, reOrderObj) => {
  const {foodLineItem, checkProductPriceAndAvail, itemMapById, reOrderAnalyticEvent, menuItemsMapById} = reOrderObj,
   foodLineItemForReorder = foodLineItem?.filter(itemInfo => {
    return checkProductPriceAndAvail(itemInfo?.item?.id, itemMapById);
  })
  if(foodLineItemForReorder?.length !== 0 && reOrderAnalyticEvent) {
    genericReOrderAnalyticHandler(foodLineItemForReorder, currencyConversionFactor, menuItemsMapById);
  }
};

export const getTrackOrderAnalytic = async (orderValue = 'NA') => {
  await AnalyticsService.registerEvent(
    events?.trackOrder?.name,
    {
      data: {
        event: 'trackOrder',
        orderValue,
      },
    },
    getDataLayer,
  );
};

export const getkeepOrderAnalytic = async (keepOrder = 'NA') => {
  await AnalyticsService.registerEvent(
    events?.keepOrderSettings?.name,
    {
      data: {
        event: WELCOME_BACK_COSTANTS.KEEP_ORDER_SETTINGS,
        keepOrder,
      },
    },
    getDataLayer,
  );
};

export const keepOrderAnalyticFn = (keepOrder) => {
  const { AU } = COUNTRY_CODE;
  if (getTenantCountryCode() === AU) {
    getkeepOrderAnalytic(keepOrder);
  }
};


import React from 'react';
import ReactDOM from 'react-dom';
import ModalComponent from '../../Modal';

/**
 * Render login form in a popup modal Component
 * @param {*} props - Object
 */
export const AlertFormInsideModel = props => {
  const { handleClose, CartAlertForm, classNames } = props;
  return ReactDOM.createPortal(
    <React.Fragment>
      <ModalComponent
        id='CartAlertopup'
        role='dialog'
        show={true}
        classNames={`alet-modal ${classNames}`}
        showCloseBtn={true}
        closeButtonHandler={handleClose}
        closeDataTestId='handler-close'
        autoFocus={true}
      >
        {CartAlertForm}
      </ModalComponent>
    </React.Fragment>,
    document.getElementById('modal-popups'),
  );
};

export default AlertFormInsideModel;

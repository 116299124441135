/* istanbul ignore next */
const dynamicImportConfig = {
  /* istanbul ignore next */
  IN: {
    // APP
    APP_HEADER_ORDER_TRACKER_COMP: import('organisms/HeaderTrackOrderComponent/Container/HeaderTrackOrderComponent'),
    APP_HEADER_ORDER_TRACKER_VIEW: import('organisms/HeaderTrackOrderComponent/Presentation/HeaderTrackOrderItems'),
    APP_GOOGLE_MAP: import('molecules/GoogleMap/GoogleMap'),

    // Active Order Page
    ACTIVE_ORDER_PAGE: import('components/activeOrders/container/activeOrders'),
    ACTIVE_ORDERS_VIEW: import('components/activeOrders/presentation/activeOrdersItems'),

    // Track Order Page
    TRACK_ORDER_PAGE: import('components/trackOrder/container/trackOrder'),
    TRACK_ORDER_VIEW: import('components/trackOrder/presentation/trackOrderView'),

    // Payment Failed Page
    PAYMENT_FAILED_VIEW: import('components/paymentFailed/presentation/paymentFailedView'),
    
    // Disposition bar
    START_ORDER_ITEMS: import('organisms/StartOrderComponent/Presentation/StartOrderItems'),
    CONTENT_DISPOSITION_ITEMS: import('organisms/SelectContentDisposition/Presentation/SelectContentDispositionItems'),

    // PDP
    MENU_PDP_DESCRIPTION_PAGE: import('components/productDescriptionPage/Container/productDescriptionPage'),
    MODIFIER_CARD_TEMPLATE: import('molecules/ModifierCard/Presentation/ModifierCardTemplate'),

    //Quantity Modifier
    QUANTITY_MODIFIER_TEMPLATE: import('molecules/QuantityModifier/Container/QuantityModifier'),
    
    // Checkout Page
    CHECKOUT_PAGE: import('components/checkout/Checkout'),
    CHECKOUT_VIEWS: import('organisms/CheckoutViews/presentation/AU/CheckoutView'),
    CHECKOUT_DISPOSITION_INFO_VIEW: import(
      'organisms/CheckoutDispositionInfo/presentation/AU/CheckoutDispositionInfoView'
    ),
    CHECKOUT_DISPOSITION_OPTIONS_VIEW: import(
      'organisms/CheckoutDispositionOptions/presentation/AU/CheckoutDispositionOptionsView'
    ),
    CHECKOUT_CONTACT_INFO_VIEW: import('organisms/CheckoutContactInfo/presentation/AU/CheckoutContactInfoView'),
    CHECKOUT_PAYMENT_VIEW: import('organisms/Payment/presentation/AU/PaymentView'),
    CHECKOUT_PRICE_SUMMARY_VIEW: import('organisms/PriceSummary/presentation/AU/PriceSummaryView'),

    // Order Process & Confirmation Page / Payment Process
    ORDER_PROCESSING_PAGE: import('components/paymentProcessPage/Container/paymentProcessing'),
    ORDER_PROCESSING_VIEW: import('organisms/orderProcessingViews/presentation/AU/orderProcessingView'),
    ORDER_PROCESSING_PROGRESS_BAR_VIEW: import(
      'organisms/orderProcessingProgressBar/presentation/AU/orderProcessingProgressBarView'
    ),

    // Order Summary & Confirmation Page / Payment Process
    ORDER_SUMMARY_PAGE: import('components/paymentProcessPage/Container/paymentProcessing'),
    ORDER_SUMMARY_VIEW: import('organisms/orderSummaryViews/presentation/AU/orderSummaryView'),
    ORDER_SUMMARY_INFO_DETAILS_VIEW: import(
      'organisms/orderSummaryInfoDetails/presentation/AU/orderSummaryInfoDetailsView'
    ),
    ORDER_SUMMARY_RAIDER_DETAILS_VIEW: import(
      'organisms/orderSummaryRaiderDetails/presentation/orderSummaryRaiderDetailsView'
    ),

    //Home page container
    HOME_PAGE_CONTAINER: import('components/homePageContainer/Container/homePageContainer'),
    HOME_PAGE_CONSTANTS: import('components/homePageContainer/Constants/homePageContainerConstants'),
    ROOT_HEADER_CONTAINER: import('atoms/EmptyWrapper'),

    // PLP
    MENU_PLP_CONSTANTS: import('organisms/MenusPLP/MenuPLPConstants/MenuPLPConstants'),
    MENU_PLP_LANDING_PAGE: import('components/productLandingPage/Container/productLandingPage'),

    // Nutation & Allergen 
    NUTRITION_ALLERGEN_LANDING_PAGE : null,

    // RecommendationItem Card
    RECOMMENDED_CONSTANTS: import('organisms/RecommendedComponent/Constants/RecommendedComponentConstant'),

    // Search Store Container
    SEARCH_STORE_TEMPLATE: import('organisms/SearchStore/Container/SearchStoreContainer'),

    // Schedule Order Page
    SCHEDULE_ORDER_TEMPLATE: import('organisms/ScheduleOrder/Container/ScheduleOrderComponent'),

    //Cart Page
    CART_PAGE_CONTAINER: import('components/cartPage/Container/CartPageContainer'),

    // Account Page
    ACCOUNT_CONTAINER: import('components/accountPage/Container/AccountContainer'),

    // Order History
    ORDER_HISTORY: import('organisms/orderHistory/container/orderHistory'),
    ORDER_HISTORY_VIEW: import('organisms/orderHistory/presentation/AU/orderHistoryView'),

    //Footer
    FOOTER_PAGE: import('organisms/FooterComponent/Container/FooterContainer'),

     //Header
     HEADER_CONTAINER: import('organisms/HeaderComponent/Container/HeaderContainer'),
     SECONDARY_HEADER_CONTAINER: import('organisms/HeaderComponent/Container/SecondaryHeader'),

    //Help
    NEED_HELP_VIEW: import('organisms/NeedHelp/Presentation/AU/NeedHelpView'),

    //Privacy Policy
    PRIVACY_POLICY_PAGE_CONTAINER: import('components/PrivacyPolicyPage/Container/PrivacyPolicyComponent'),

    //Terms Of Use
    TERMS_OF_USE_CONTAINER: import('components/TermsOfUsePage/Container/TermsOfUseComponent'),
   
    //Signout Page
    SIGN_OUT_PAGE_CONTAINER: import('components/SignOutPage/Container/SignOutPage'),

    //Brand page route
    BRAND_PAGE_ROUTE: import('components/BrandPageRoute/BrandPageRoute'),
  },
  AU: {
    /* istanbul ignore next */
    // APP
    APP_HEADER_ORDER_TRACKER_COMP: import('organisms/HeaderTrackOrderComponent/Container/AU/HeaderTrackOrderComponent'),
    APP_HEADER_ORDER_TRACKER_VIEW: import('organisms/HeaderTrackOrderComponent/Presentation/AU/HeaderTrackOrderView'),
    APP_GOOGLE_MAP: import('molecules/GoogleMap/GoogleMap'),

    // Active Order Page
    ACTIVE_ORDER_PAGE: import('components/activeOrders/container/AU/ActiveOrders'),
    ACTIVE_ORDERS_VIEW: import('components/activeOrders/presentation/AU/ActiveOrdersView'),

    // Track Order Page
    TRACK_ORDER_PAGE: import('components/trackOrder/container/AU/TrackOrder'),
    TRACK_ORDER_VIEW: import('components/trackOrder/presentation/AU/trackOrderView'),

    // Payment Failed Page
    PAYMENT_FAILED_VIEW: import('components/paymentFailed/presentation/AU/paymentFailedView'),
    
    // Disposition bar
    START_ORDER_ITEMS: import('organisms/StartOrderComponent/AU/Presentation/StartOrderItems'),
    CONTENT_DISPOSITION_ITEMS: import('organisms/SelectContentDisposition/AU/Presentation/SelectContentDispositionItems'),

    // PDP
    MENU_PDP_DESCRIPTION_PAGE: import('components/productDescriptionPage/AU/Container/productDescriptionPage'),
    MODIFIER_CARD_TEMPLATE: import('molecules/ModifierCard/AU/Presentation/ModifierCardTemplate'),
    PDP_CUSTOMIZATION_PAGE: import(
      'organisms/ProductListingModalOptions/AU/Container/ProductListingModalOptionsComponent'
    ),
    PDP_NON_CUSTOMIZATION_PAGE: import(
      'organisms/ProductListingCustomizationComponent/Container/ProductListingCustomizationComponent'
    ),

    //RDP
    RDP_PAGE: import('organisms/RestaurantDetailPage/Container/RestaurantDetailComponent.js'),
    //Quantity Modifier
    QUANTITY_MODIFIER_TEMPLATE: import('molecules/QuantityModifier/AU/Container/QuantityModifier'),

    // Checkout Page
    CHECKOUT_PAGE: import('components/checkout/container/Checkout'),
    CHECKOUT_VIEWS: import('organisms/CheckoutViews/presentation/AU/CheckoutView'),
    CHECKOUT_DISPOSITION_INFO_VIEW: import(
      'organisms/CheckoutDispositionInfo/presentation/AU/CheckoutDispositionInfoView'
    ),
    CHECKOUT_DISPOSITION_OPTIONS_VIEW: import(
      'organisms/CheckoutDispositionOptions/presentation/AU/CheckoutDispositionOptionsView'
    ),
    CHECKOUT_CONTACT_INFO_VIEW: import('organisms/CheckoutContactInfo/presentation/AU/CheckoutContactInfoView'),
    CHECKOUT_PAYMENT_VIEW: import('organisms/Payment/presentation/AU/PaymentView'),
    CHECKOUT_PRICE_SUMMARY_VIEW: import('organisms/PriceSummary/presentation/AU/PriceSummaryView'),

    // Order Process & Confirmation Page / Payment Process
    ORDER_PROCESSING_PAGE: import('components/orderProcessing/container/orderProcessing'),
    ORDER_PROCESSING_VIEW: import('organisms/orderProcessingViews/presentation/AU/orderProcessingView'),
    ORDER_PROCESSING_PROGRESS_BAR_VIEW: import(
      'organisms/orderProcessingProgressBar/presentation/AU/orderProcessingProgressBarView'
    ),

    // Order Summary & Confirmation Page / Payment Process
    ORDER_SUMMARY_PAGE: import('components/orderSummary/container/orderSummary'),
    ORDER_SUMMARY_VIEW: import('organisms/orderSummaryViews/presentation/AU/orderSummaryView'),
    ORDER_SUMMARY_INFO_DETAILS_VIEW: import(
      'organisms/orderSummaryInfoDetails/presentation/AU/orderSummaryInfoDetailsView'
    ),
    ORDER_SUMMARY_RAIDER_DETAILS_VIEW: import(
      'organisms/orderSummaryRaiderDetails/presentation/AU/orderSummaryRaiderDetailsView'
    ),

    // RecommendationItem Card
    RECOMMENDED_CONSTANTS: import('organisms/RecommendedComponent/AU/Constants/RecommendedComponentConstant'),

    //Home page container
    HOME_PAGE_CONSTANTS: import('components/homePageContainer/Constants/AU/homePageContainerConstants'),
    HOME_PAGE_CONTAINER: import('components/homePageContainer/Container/AU/homePageContainer'),
    ROOT_HEADER_CONTAINER: import('app/RootHeaderContainer'),

    // Search Store Container
    SEARCH_STORE_TEMPLATE: import('organisms/SearchStore/AU/Container/SearchStoreContainer'),

    // Schedule Order Page
    SCHEDULE_ORDER_TEMPLATE: import('organisms/ScheduleOrder/AU/Container/ScheduleOrderContainer'),

    // PLP
    MENU_PLP_CONSTANTS: import('organisms/MenusPLP/AU/MenuPLPConstants/MenuPLPConstants'),
    MENU_PLP_LANDING_PAGE: import('components/productLandingPage/Container/AU/productLandingPage'),
    
    // Nutation & Allergen 
    NUTRITION_ALLERGEN_LANDING_PAGE : import('components/NutritionAllergenLandingPage/Container/AU/NutritionAllergenLandingPage'),

    //Cart Page
    CART_PAGE_CONTAINER: import('components/cartPage/AU/Container/CartPageContainer'),

    // Account Page
    ACCOUNT_CONTAINER: import('components/accountPage/AU/Container/AccountContainer'),

    // Order History
    ORDER_HISTORY: import('organisms/orderHistory/container/orderHistory'),
    ORDER_HISTORY_VIEW: import('organisms/orderHistory/presentation/AU/orderHistoryView'),

    //Footer
    FOOTER_PAGE: import('organisms/FooterComponent/AU/Container/FooterContainer'),

    //Help
    NEED_HELP_VIEW: import('organisms/NeedHelp/Presentation/AU/NeedHelpView'),

    // //Header
    HEADER_CONTAINER: import('organisms/HeaderComponent/AU/Container/HeaderContainer'),
    SECONDARY_HEADER_CONTAINER: import('organisms/HeaderComponent/AU/Container/SecondaryHeader'),
    
    //Privacy policy
    PRIVACY_POLICY_PAGE_CONTAINER: import('components/PrivacyPolicyPage/Container/AU/PrivacyPolicyComponent'),

    //Terms Of Use
    TERMS_OF_USE_CONTAINER: import('components/TermsOfUsePage/Container/AU/TermsOfUseComponent'),

    //Signout Page
    SIGN_OUT_PAGE_CONTAINER: import('components/SignOutPage/Container/AU/SignOutPage'),

    //Brand page route
    BRAND_PAGE_ROUTE: import('components/BrandPageRoute/AU/BrandPageRoute'),
   
    // Contact Page 
    CONTACT_PAGE_CONTAINER: import('components/contactUsPage/AU/Container/ContactKFCPageContainer'),

    //Thank you Page
    THANKYOU_PAGE_CONTAINER: import('components/ThankYouPage/Container/ThankYouPage')
  },
};

export default dynamicImportConfig;

const constants = {
  IS_DELIVERY: 'DELIVERY',
  IS_TAKEAWAY: 'PICKUP',
  IS_DINEIN: 'DINE-IN',
  CHECKOUT_TITLES: {
    TITLE: 'cartCheckout',
    DELIVERY_INFO: 'deliveryInfo',
    TAKEAWAY_INFO: 'takeawayInfo',
    DINEIN_INFO: 'dineInInfo',
    DELIVERY_OPTIONS: 'deliveryOptions',
    TAKEAWAY_OPTIONS: 'takeawayOptions',
    DINEIN_OPTIONS: 'dineInOptions',
    CONTACT_INFO: 'contactInfo',
    PAYMENT: 'payment',
  },
  CHECKOUT_SUMMARY: {
    QUANTITY_TEXT: 'cartItem',
    QUANTITYS_TEXT: 'cartItems',
    SUBTOTAL: 'cartSubTotal',
    GST: 'cartGst',
    ADDITIONAL_TAX: 'cartIncluTax',
    RESTRNT_HANDLING: 'restaurantHandling',
    ADD_HOPE_HEADER: 'addHopeHeader',
    ADD_HOPE_TEXT: 'addHopeText',
    ADD_BAG_TEXT: 'largeCarryBagContent',
  },
  ERROR_ALERT: 'checkoutAlert',
  DELIVERY: {
    LEAVE_AT_MY_DOOR: {
      TITLE: 'leaveatMyDoor',
      DESC: 'leaveatMyDoorDesc',
    },
    HAND_IT_TO_ME: {
      TITLE: 'handItToMe',
      DESC: '',
      PLACE_HOLDER: 'deliveryInstructions',
    },
  },
  TAKEAWAY: {
    WALK_IN_THE_STORE: {
      TITLE: 'walkInStore',
      DESC: '',
    },
    KFC_TO_YOUR_CAR: {
      TITLE: 'KfcToYourCar',
      DESC: 'KfcToYourCarDesc',
      PLACE_HOLDER: 'vehicleInformation',
    },
  },
  DINE_IN: {
    DINEIN_WALK_IN_THE_STORE: {
      TITLE: 'DineInStore',
    },
  },
  ADD_PAY_METHOD: 'addPaymentMethodHeader',
  ASAP: 'asap',
  ADDRESS_PH: 'address2Placeholder',
  AGREEMENT_LABEL: 'subscribeEmail',
  CONTINUE_PAYMENT: 'continuePayment',
  PLACE_ORDER: 'placeOrder',
  FORM_NAMES: {
    FULL_NAME: 'fullName',
    PHONE: 'phone',
    EMAIL: 'email',
    SUBSCRIPTION: 'subscription',
    VEHICAL_INFORMATION: 'vehicalInformation',
    DELIVERY_INSTRUCTION: 'deliveryInstruction',
  },
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  largeCarryBagText: 'largeCarryBag',
  addHopeText: 'addhope',
  PRICE_DECIMAL_DIGIT: 2,
  PHONEPE_SWITCH_CONSTANTS: {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
  },
  mandatorynote: 'mandatorynote',
  IS_CURBSIDE: 'curbside'
};

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FULL_NAME_REGEX = /^(?!\s)[A-Za-z ]+$/;
export default constants;

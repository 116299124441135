import React from 'react';

/**
 * Close Button Component
 */
export const ButtonComp = props => {
  const { ariaLable, className, onClickHandler,onKeyDownHandler, ...rest } = props;
  /**
   * On Key Press Handler
   * @param {*} evt - Event Object
   */

  return (
    <button
      className={className || 'closebtn-cls'}
      role='button'
      aria-label={ariaLable || 'Close'}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      {...rest}
    >
      {props.children}
    </button>
  );
};

export default ButtonComp;

/**
 * Constants Used For container Component.
 */
 const HOME_PAGE_CONTAINER_CONSTANTS = {
  GENERAL_ROUTING: 'General Routing/Communication',
  HOME_PAGE_OFFERS: 'Home Page Offers',
  // DISPOSITION_START_ORDER: 'Disposition - Start Order',
  MARKETING_HERO_3: 'Marketing Hero 3',
  // DISPOSITION_LOCALIZED: 'Disposition - Localized',
  CRM_SIGNUP: 'CRM SignUP',
  PROMOS_REWARDS: 'promosandrewards',
  BROWSER_CATEGORIES: 'Browser Categories',
  OFFERS_DEALS: 'Offers and Deals',
  DISPOSITION: 'disposition',
  PDP_DISPOSITION: 'pdpDisposition',
  CAROUSAL: 'carousalComponent',
  ORDERSERV: 'orderServComponent',
  ROUTING: 'routingComponent',
  CRM: 'crmSignUp',
  LTO_BANNER: 'limitedTimeOffers',
  ORDER_IT_AGAIN: 'Order it again',
  WELCOME_MESSAGE: 'Welcome Message',
  RECOMMENDATION: 'Recommendation',
  INDIA: 'INDIA',
  PAYMENT_FAILED_MESSAGE: 'paymentdeclined',
  PAYMENT_PRE_VALIDATION_FAILED_MESSAGE: 'paymentPreValidationFailed',
  SERVICE_CHANNEL: 'web',
  OFFERS_DEALS_MODE: 'Web',
  KFC_Gift_Card: 'KFC Gift Card',
  BRAND: 'brand',
  DOWNLOADAPP: 'downloadapp',
  KAU: '/kau/',
  LOGIN_ACTION: 'login-actions/registration?client_id=',
  REDIRECT_PATH_NAME: 'redirect_path_name',
  SIGNOUT: '/signout',
  OPEN_ID_CONNECT: '/openid-connect/',
  CODE_CHALLENGE: 'code_challenge',
  CODE_VERIFIER: 'code_verifier',
  REGISTRATIONS: 'registrations?',
  PAGE_TYPE_PDP: 'pdppage',
  PAGE_TYPE_HOME: 'homepage',
  PAGE_TYPE_CATERING: 'catering',
  CATERING_DISPOSITION: 'cateringDisposition',
  SIINGLE_BANNER:'singleBanner'
};

export default HOME_PAGE_CONTAINER_CONSTANTS;

const FOOTER_CONSTANT = {
  FOOTER_LOGO: 'logoicon',
  FOOTER_SECTION: 'footerSection',
  FIND_A_KFC_SECTION: 'Find A KFC',
  APP_LINKS: 'AppLinks',
  SOCIAL_SECTION: 'Social Links',
  COPYRIGHT: 'Copyright',
  FOOTER_LINKS: 'footer-links',
  SOCIAL_ICONS: 'social-icons',
  INDIA: 'INDIA',
  ORDER_LOOKUP: 'orderLookup',
  ORDER_LOOKUP_NAME: 'Order Lookup',
  PRIVACY_POLICY: 'Privacy Policy ',
  TERMS_OF_USE: 'Terms of Use ',
  OUR_ADS: 'Our Ads',
  ERROR_MESSAGE: 'invaidInputs',
  PHONE_NUM: 'phoneNumber',
  ORDER_NUMBER: 'Order Number*',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  FOOTER_MENU: 'Menu',
  FAQ: 'FAQ',
  GIFT_CARD: 'Gift Card',
  ORDER_NUMBER_HEADER: 'Order Number*',
  PHONE_NUM_HEADER: 'phonePlaceholder',
  ORDERHASH: 'ORDER# ',
  YOUR_ORDER: 'YOUR ORDER',
  DELIVER_TO: 'delivery To',
  ORDER_FROM: 'order From',
  GET_DIRECTION: 'getDirection',
  DISCOUNT: 'DiscountText',
  TOTAL: 'total',
  COMPLETED: 'completed',
  SUB_TOTAL: 'subTotal',
  PAYMENT: 'payment',
  GETHELP: 'Get Help',
  KFC_INDIA: 'KFC India',
  USER_EMAIL: 'user_email',
  USER_PHONE: 'user_phone',
  EMAIL_REGEX: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  YOUR_EMAIL_PLACEHOLDER: 'useremailplaceholder',
  PHONE_PLACEHOLDER: 'userphoneplaceholder',
  INVALID_EMAIL: 'invalidEmailId',
  INVALID_PHNUM: 'invalidPhoneNum',
  EMAIL_ADDRESS: 'emailAddress',
  INVALID_ORDERID: 'invalidOrderId',
  DELIVERY: 'DELIVERY',
  CONTACT_US: 'Contact Us',
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  largeCarryBagText: 'largeCarryBag',
  addHopeText: 'addhope',
  KFC_FEEDBACK: 'KFC Feedback',
  INTERNAL: 'internal',
  EXTERNAl: 'external'
};
export default FOOTER_CONSTANT;

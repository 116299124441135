import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';

import { NOT_AVAILABLE, COUNTRY_CODE, ANALYTICS_SHARED_CONSTANTS } from 'common/constants/SharedConstants';
import { ChannelManager } from 'common/manager';
import { calculatePrice, getDataLayer, getUserType, getValueOrDefaultObject, getCouponDetails, getTenantCountryCode } from 'common/utilities/utils';
/*********************************************************************
 * updatePaymentAnalytics
 * Params - cartData, currencyConversionFactor, tender, categories
 *********************************************************************/
export const updatePaymentAnalytics = (cartData, currencyConversionFactor, tender, categories) => {
  const itemsArray = [];
  cartData?.foodLines?.forEach((itemDetails, index) => {
    const { id = NOT_AVAILABLE, name = NOT_AVAILABLE } =
      categories?.find(cat => cat.products.find(p => p.name === itemDetails.item?.name)) || {};
    itemsArray.push({
      item_name: itemDetails?.item?.name || NOT_AVAILABLE,
      item_id: itemDetails?.item?.id || NOT_AVAILABLE,
      price: calculatePrice(itemDetails?.unitPrice, currencyConversionFactor),
      item_category: name,
      item_list_name: name,
      item_list_id: id,
      index: index + 1,
      quantity: itemDetails?.quantity || 0,
    });
  });
  if ( getTenantCountryCode() === COUNTRY_CODE.IN ){
    paymentInfoClickAnalytics(tender?.name, itemsArray);
  }
};

export const logEvent = (name, content) => {
  // eslint-disable-next-line no-console
  AnalyticsService.registerEvent(name, content).then(() => console.log(`logEvent '${name}' successful`));
};

/*********************************************************************
 * guestContactDetailsClickAnalytics
 * Params - none
 *********************************************************************/
export const guestContactDetailsClickAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events.guestContactDetails.name,
    {
      '%event%': 'guestContactDetails',
    },
    getDataLayer,
  );
};

/*********************************************************************
 * addContactInfoClickAnalytics
 * params - itemsArray
 *********************************************************************/
export const addContactInfoClickAnalytics = async itemsArray => {
  await AnalyticsService.registerEvent(
    events.addContactInfo.name,
    {
      data: {
        event: 'add_contact_info',
        ecommerce: {
          checkout_cart: {
            items: itemsArray,
          },
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * beginCheckoutClickAnalytics
 * params - itemsArray
 *********************************************************************/
export const beginCheckoutClickAnalytics = async itemsArray => {
  await AnalyticsService.registerEvent(
    events.beginCheckoutPwa.name,
    {
      data: {
        event: 'begin_checkout',
        ecommerce: {
          checkout_cart: {
            items: itemsArray,
          },
        },
      },
    },
    getDataLayer,
  );
};

export const beginCheckoutClickAnalyticsStep1 = async (itemsArray, userType) => {
  await AnalyticsService.registerEvent(
    events?.beginCheckoutPwa?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.BEGIN_CHECKOUT,
        userType: userType,
        ecommerce: {
          checkout_cart: {
            items: itemsArray,
          },
        },
      },
    },
    getDataLayer,
  );
};

export const RecommendedItemAnalytic = async (userType, itemsList, totalAmount) => {
  await AnalyticsService.registerEvent(
    events?.add_to_cart?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_TO_CART,
        userType,
        ecommerce: {
          currency: ANALYTICS_SHARED_CONSTANTS.AUD,
          value: totalAmount,
          items: itemsList,
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * paymentInfoClickAnalytics
 * param - paymentType, itemsArray
 *********************************************************************/
export const paymentInfoClickAnalytics = async (paymentType, itemsArray) => {
  await AnalyticsService.registerEvent(
    events.paymentInfoClick.name,
    {
      data: {
        event: 'add_payment_info',
        paymentType: paymentType,
        ecommerce: {
          checkout_cart: {
            items: itemsArray,
          },
        },
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * checkoutDetailsClickAnalytics
 * params - none
 *********************************************************************/
export const checkoutDetailsClickAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events.checkoutClick.name,
    {
      data: {
        event: 'checkoutClick',
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * purchaseOrderDetailsInfo
 * params - itemObj
 *********************************************************************/
export const purchaseOrderDetailsInfo = async (userType,itemObj) => {
  await AnalyticsService.registerEvent(
    events?.purchaseOrder?.name,
    {
      data: {
        event: 'purchase',
        userType: userType,
        ecommerce: itemObj || {},
      },
    },
    getDataLayer,
  );
};

/*********************************************************************
 * updatePurchaseOrderDetailsAnalytics
 * param - additionalChargeLines,    currencyCode,    currencyConversionFactor,    discountLines,    foodLines,    paymentReferences,    total,    taxTotal,
 *********************************************************************/
export const updatePurchaseOrderDetailsAnalytics = params => {
  const {
    additionalChargeLines,
    currencyCode,
    currencyConversionFactor,
    discountLines,
    foodLines,
    paymentReferences,
    total,
    inclusiveTaxTotal,
    userStatus,
  } = params?.orderDetails;
  const { couponCode = '', discountTotal = '' } = getCouponDetails(params?.orderDetails);
  const { localized = false, loggedIn = false} = getValueOrDefaultObject(userStatus);
  const userType= getUserType(localized, loggedIn);
  const orderItemsList = [];
  foodLines?.forEach((foodLineItem,idx) => {
    
    orderItemsList?.push({
      currency: ANALYTICS_SHARED_CONSTANTS.AUD,
      discount: discountTotal / currencyConversionFactor || '',
      coupon: couponCode,
      item_name: foodLineItem?.item?.dname?.[0]?.value || NOT_AVAILABLE,
      item_id: foodLineItem?.item?.id || NOT_AVAILABLE,
      price: foodLineItem?.item?.unitPrice / currencyConversionFactor || NOT_AVAILABLE,
      // item_variant: itemVariant || NOT_AVAILABLE,
      quantity: foodLineItem?.quantity || NOT_AVAILABLE,
      item_category: foodLineItem?.item?.categoryName,
      item_list_name: foodLineItem?.item?.categoryName,
      item_list_id: '',
      index: idx,
    });
  });

  const transactionId =
    ChannelManager?.shouldGoogleSpotIgnoreOloFailure && ChannelManager?.transactionId
      ? ChannelManager?.transactionId
      : paymentReferences?.[0]?.transactionId ?? NOT_AVAILABLE;

  purchaseOrderDetailsInfo(userType,{
    transaction_id: transactionId,
    value: total ? total / currencyConversionFactor : NOT_AVAILABLE,
    tax: inclusiveTaxTotal ? inclusiveTaxTotal / currencyConversionFactor : NOT_AVAILABLE,
    shipping:
      additionalChargeLines?.[0]?.chargeType === 'delivery-charge'
        ? additionalChargeLines?.[0]?.amount / currencyConversionFactor
        : NOT_AVAILABLE,
    currency: ANALYTICS_SHARED_CONSTANTS.AUD,
    coupon: couponCode,
    items: orderItemsList || NOT_AVAILABLE,
  });
};

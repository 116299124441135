export const RESTRICTED_BAD_WORDS = [
  'anal',
  'anus',
  'arse',
  'ass',
  'ass fuck',
  'ass hole',
  'assfucker',
  'asshole',
  'assshole',
  'bastard',
  'bitch',
  'black cock',
  'bloody hell',
  'cockfucker',
  'cocksuck',
  'cocksucker',
  'coon',
  'crap',
  'cunt',
  'cyberfuck',
  'erect',
  'erection',
  'faggot',
  'fuck',
  'Fuck off',
  'fuck you',
  'fuckass',
  'fuckhole',
  'god damn',
  'gook',
  'homoerotic',
  'lesbian',
  'lesbians',
  'mother fucker',
  'motherfuck',
  'motherfucker',
  'negro',
  'nigger',
  'orgasim',
  'orgasm',
  'penis',
  'penisfucker',
  'piss',
  'piss off',
  'porno',
  'pornography',
  'pussy',
  'retard',
  'sadist',
  'shit',
  'slut',
  'son of a bitch',
  'suck',
  'tits',
  'viagra',
  'whore',
];

import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const useCanonicalUrl = () => {
  const useLocationHook = useLocation();
  const [canonicalUrl, setCanonicalUrl] = useState('');

  useEffect(() => {
    const origin = window?.location?.origin;
    const pathName = useLocationHook?.pathname ?? '';
    setCanonicalUrl(`${origin}${pathName}`);
  }, [useLocationHook?.pathname]);

  return { canonicalUrl };
};

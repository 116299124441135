import { Provider } from 'react-redux';
import { UserOrderContextProvider } from '../context/context';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { PersistGate } from 'redux-persist/integration/react';
import { newStore } from '@kfc-global/react-shared/redux/Store';
import storage from 'redux-persist/lib/storage';
import AppWrapper from './App';
import App from '@kfc-global/react-shared';
import { APP_SOURCE_WEB } from 'app/Constants';

const { store, persistor } = newStore(storage, null, [
  'appConfigsReducer',
  'giftCardReducer',
  'giftCardPurchaseReducer',
  'marketConfigReducer',
  'microAppPaymentReducer',
  'orderReducer',
  'paymentsGiftCardReducer',
  'paymentsGoogleSpotReducer',
  'postOrderReducer',
  'paymentsReducer',
]);

const KFCApp = () => {
  const tenantCode = process?.env?.REACT_APP_TENANT_CODE;
  const appApiBaseUrl = process?.env?.REACT_APP_API_BASE_URL;

  if (!tenantCode || !appApiBaseUrl) {
    throw new Error(
      'Env variables REACT_APP_TENANT_CODE or REACT_APP_API_BASE_URL is missing.' +
        ' Please provide them during build or start',
    );
  }
  console.log(`Loading Application for Tenant: ${tenantCode}`);
  App.appApiBaseUrl = appApiBaseUrl;
  App.channel = APP_SOURCE_WEB;

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UserOrderContextProvider>
              <AppWrapper />
            </UserOrderContextProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default KFCApp;

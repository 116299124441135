export const MENU_PDP_PAGE_CONSTANTS = {
  SLIDES_TO_SHOW: 4,
  SLIDES_TO_SCROLL: 1,
  // FADE_CARD_INDEX: window.innerWidth < 1330 ? 3 : 4,
  FADE_CARD_INDEX: 3,
  CUSTM_EXPAND_TYPE: 'customExpandType',
  EXPAND_ACCORDIAN: 'expandAccordianById',
  MODGROUP_IDS_UPDATE: 'modGroupIdsUpdate',
  RESET_MODGROUP_IDS_UPDATE: 'resetModGroupIdsUpdate',
  UPDATE_DEFAULT_MODGROUP: 'UPDATE_DEFAULT_MODGROUP',
  IS_OUT_OF_STOCK: 'OutOfStock',
  IS_IN_STOCK: 'InStock',
  SET_PDP_DEEPLINK_MODE: 'SET_PDP_DEEPLINK_MODE',
  SET_MULTI_PRODUCT_VIEW_MODAL: 'SET_MULTI_PRODUCT_VIEW_MODAL',
  SET_PDP_PROMO_MODE: 'SET_PDP_PROMO_MODE',
  SHORT: 'short',
  LONG: 'long',
  READ_MORE: 'readMore',
  READ_LESS: 'readLess',
  MAX_LENGTH_READ: 35,
  YYYYMMDD: 'YYYY-MM-DD',
  YYYYMMDD_hhmm_A: 'YYYY-MM-DD hh:mm A',
  INDIA: 'INDIA',
  UPSELL: 'upsell',
  CROSSELL: 'crossell',
  MAKE_IT_COMBO_HEADER: 'makeItACombo',
  MAKE_IT_COMBO_SUBHEADER: 'comboSubHeader',
  PDP: 'PDP',
  PDP_CHOOSE_STORE: 'pdpChooseStore',
  REGULAR_SIZE_SELECTED: 'REGULAR_SIZE_SELECTED',
  UPSIZE_SELECTED: 'UPSIZE_SELECTED',
  REGULAR_SIZE_TITLE: 'pdpRgularSizeTitle',
  REGULAR_SIZE_DESC: 'pdpRegularSizeDesc',
  UPSIZE_TITLE: 'pdpUpsizeTitle',
  UPSIZE_DESC: 'pdpUpsizeDesc',
  ALLERGY_NUTRITION_TEXT: 'pdpAllergyNutrition',
  ADDTOCART: 'pdpAddToCart',
  SHOW_PDP_CUSTOM_MODAL: 'SHOW_PDP_CUSTOM_MODAL',
  UPDATE_SHOW_PDP_CUSTOM_MODAL: 'UPDATE_SHOW_PDP_CUSTOM_MODAL',
  RESET_SHOW_PDP_CUSTOM_MODAL: 'RESET_SHOW_PDP_CUSTOM_MODAL',
  PDP_ITEM_CUSTOM_TYPE: 'PDP_ITEM_CUSTOM_TYPE',
  PDP_ITEM_UPSIZE_ID: 'PDP_ITEM_UPSIZE_ID',
  PDP_L3_CUSTOMIZED_DATA: 'PDP_L3_CUSTOMIZED_DATA',
  PDP_PAYLOAD_DATA: 'PDP_PAYLOAD_DATA',
  SHOWTOASTALERT: 'SHOWTOASTALERT',
  BASEPRICE: 'basePrice',
  NEWBASEPRICE: 'newBasePrice',
  INEXTRAS: 'inExtrasText',
  RESET_CUSTOMIZATION: 'RESET_CUSTOMIZATION',
  BROWSE_MENU_TITLE: 'browseMenuTitle',
  ITME_NOT_AVAILABLE: 'itemNotAvailable',
  BROWSE_MENU: 'browseMenu',
  SIXTEEN: 16,
  FOURTEEN: 14,
  TWENTY_SIX: 26,
  MAIN_ENTREE: 'mainEntree',
  OUT_OF_STOCK_TEXT: 'outOfStock',
  INSIDE_YOUR_MEAL: 'insideYourMeal',
  MEAL_OPTIONS: 'mealOptions',
  BUNDLE_OPTIONS: 'bundleOptions',
  PDP_PRODUCT_MOBILE_IMG: 'pdp-product-mobile-image',
  PDP_PRODUCT_IMG: 'pdp-product-image',
  PDP_CUSTOM_CONTAINER: 'pdp-custm-container pdp-custm-container-sopac',
  HASH_SYMBOL: '##',
  RECIPE: 'RECIPE',
  DEFAULT_REASON: 'defaultreason',
  PEROPTIONMAX_KEY:'peroptionmaximum',
  PEROPTIONMIN_KEY:'peroptionminimum',
  MAXDISTINCT_KEY:'maxdistinctoptions',
  PDP_DATA_FOR_UPDATE_FOODLINE: 'PDP_DATA_FOR_UPDATE_FOODLINE',
  CUSTOMISE_FLAG_CART: 'CUSTOMISE_FLAG_CART',
  SET_PDP_PAGE_STATUS: 'SET_PDP_PAGE_STATUS',
  SET_PDP_BUTTON_DISABLED: 'SET_PDP_BUTTON_DISABLED',
  RECOMMENDED_SECTION_ITEM_CLICK: 'RECOMMENDED_SECTION_ITEM_CLICK',
  MODGROUP_MAX_RADIOBUTTON : 1,
  ADDONS: 'addons',
  PRICE: 'price',
  MODGROUPMAX: 'modgrpMax',
  EXCLUDED: 'isExcluded', 
  NA: 'NA',
  SELECTED_ITEM_NOT_AVAILABLE: 'selectedItemNotAvailable',
  PDP_CUSTOMIZATION_PAGE: 'PDP_CUSTOMIZATION_PAGE', 
  PDP_NON_CUSTOMIZATION_PAGE: 'PDP_NON_CUSTOMIZATION_PAGE'
};
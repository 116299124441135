import React from 'react';import PropTypes from 'prop-types';

/**
 * Contained Button Component
 */

export const ButtonType = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
}

export const ContainedButton = props => {
  const {
    ariaLable,
    className = "",
    variant = 'secondary',
    inactive,
    inverted,
    hasPrices,
    loading,
    small,
    fullWidth,
    onClickHandler,
    onKeyDownHandler,
    ...rest
  } = props;
  return (
   <button
    className={`button-contained ${className}`}
    type="button"
    data-type={variant}
    data-full-width={fullWidth}
    data-small={small}
    data-inactive={inactive}
    data-inverted={inverted}
    data-loading={loading}
    data-has-prices={hasPrices}
    data-disabed={inactive}
    role='button'
    aria-label={ariaLable || 'Close'}
    onClick={onClickHandler}
    onKeyDown={onKeyDownHandler}
    {...rest}
   >
     {props.children}
   </button>
  );
};

ContainedButton.propTypes = {
  ariaLable: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(ButtonType)).isRequired,
  small: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inverted: PropTypes.bool,
  inactive: PropTypes.bool,
  loading: PropTypes.bool,
  hasPrices: PropTypes.bool,
  onClickHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func
}


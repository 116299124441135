import React, { Component } from 'react';
import { ButtonType, ContainedButton } from "../atoms/Buttons";
import { IMAGE_PATH } from '../common/ImageConstants/ImagePathUtils';

const clearLocalStorage = () => {
  window.location.replace('/');
  window.localStorage.clear();
};
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  /**
   * Is Used to Catch the error If any of the Child Component Has Error
   */
  componentDidCatch = (error, errorInfo) => {
    this.setState({
      error,
      errorInfo,
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    if (error && errorInfo) {
      return (
        <div className='error-box'>
          <img
            src={IMAGE_PATH.CartError}
          />
          <h2 className={"error-headline"}>Something Went Wrong</h2>
          <p>Oops! Someone must have spilled gravy on some circuits and caused an error. Please try again later.</p>
          <ContainedButton
              onClick={clearLocalStorage}
              variant={ButtonType.tertiary}
          >
            Home
          </ContainedButton>
          <details>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

// Export default
export default ErrorBoundary;

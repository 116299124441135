const NOT_FOUND_404_ERROR = {
  ERROR_CODE: '404',
  THERE_NO_FRIED_TEXT: "THERE'S NO FRIED",
  CHICKEN_PAGE_TEXT: 'CHICKEN ON THIS PAGE...',
  TAKE_ME_HOME_TEXT: 'takeMeHome',
  SIGN_UP_TEXT: 'signUpText',
  SIGN_IN_TEXT: 'signIn',
  SIGN_OUT_MESSAGE1: 'signOutText1',
  SIGN_OUT_MESSAGE2: 'signOutText2',
  SIGN_OUT_FAIL_MESSAGE1: 'signOutFailText1',
  SIGN_OUT_FAIL_MESSAGE2: 'signOutFailText2',
  DELETE_ACCOUNT_MESSAGE: 'deleteAccountText'
};

export default NOT_FOUND_404_ERROR;
